<template>
<div>
  <div v-if="$apollo.queries.problem.loading">
    <span>Loading ....</span>
  </div>
  
  <v-row>
      <v-col cols="5" col-md=5 xs=12 class="ml-5 problem-height">
        <div class="d-flex justify-center my-15" v-if="error">
          <v-alert type="warning" outlined close-icon="text-capitalize"> 
            {{ error }}
          </v-alert>
        </div>
        <v-card color="transparent" flat v-else>
          <v-card-title>{{ problem.title }}</v-card-title>
          <v-card-text >
            <vue-mathjax class="mathjax-img" :formula="markDownHtml" :safe="false"></vue-mathjax>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between align-end absolute" >
            <v-btn :disabled="disablePrev" color="primary" @click="setPrevious"> Previous</v-btn>
            <v-btn :disabled="disableNext" color="primary" @click="setNext"> Next</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <v-card class="pa-o rounded-lg" height="100%" dark flat>
          <v-card-title class="pb-1">
            <v-spacer></v-spacer>
            <v-select
              :items="themes"
              item-text="label"
              item-value="value"
              outlined
              dense
              class="select mr-5"
              label="Themes"
              v-model="editorTheme"
              @change="setEditor"
            >
            </v-select>
            <v-icon @click="showModal = true">mdi-refresh</v-icon>
          </v-card-title>
          <v-card-text class="pa-o">
            <pre id="editor"></pre>
          </v-card-text>
          <v-card-text v-if="loading">
            <span> Running ...... </span>
          </v-card-text>
          <div v-show="message">
              <v-card-text>
                <span class="headline"> Result:</span>
                <div v-if="actionClass === 'success'">
                  <div>
                  |{{ user.username }}>Message:  <span :class="`${actionClass}--text`">{{ message }} </span>
                  </div>
                  <span>
                  |{{ user.username }}>Eigentokens: {{ eigenTokens }} <v-icon color="" class="mt-n1" small> mdi-lambda </v-icon> <span class="font-weight-bold headline">  </span>
                  </span>
                </div>
                <div v-else>
                  <div>
                  |{{ user.username }}>Error Type:  <span :class="`${actionClass}--text`">{{ errorType }} </span>
                  </div>
                  <span>
                  |{{ user.username }}>Error Message: <span :class="`${actionClass}--text`">{{ message }} </span>
                  </span>
                </div>
              </v-card-text>
          </div>
         
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :color="btnColor" class="px-5" light v-if="message" @click="reset"> Reset </v-btn>
            <v-btn :color="btnColor" class="px-5 ml-2" light :loading="loading" @click="submitCode">Run</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
  </v-row>

  <v-dialog
  v-model="showModal"
  width="350px"
  >
    <v-card flat>
      <v-card-title>
        <svg width="64px" height="64px" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="32" cy="32" r="32" fill="#FFE9D8"/>
          <path d="M30.4 36.8H33.6V40H30.4V36.8ZM30.4 24H33.6V33.6H30.4V24ZM31.984 16C23.152 16 16 23.168 16 32C16 40.832 23.152 48 31.984 48C40.832 48 48 40.832 48 32C48 23.168 40.832 16 31.984 16ZM32 44.8C24.928 44.8 19.2 39.072 19.2 32C19.2 24.928 24.928 19.2 32 19.2C39.072 19.2 44.8 24.928 44.8 32C44.8 39.072 39.072 44.8 32 44.8Z" fill="#B71F25"/>
        </svg>
      </v-card-title>
      <v-card-text>
        <span> This Action will reset the editor to it's original skeleton code.</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :color="primaryBlue" @click="showModal = false" dark> Cancel</v-btn>
        <v-btn :color="primaryBlue" dark @click="resetToSkeleton"> Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import colorMxn from '@/mixins/color_mixin';
import { PROBLEM_QUERY, POST_ANSWER } from '@/queries/problem';
import { EIGEN_TOKENS } from '@/queries/dashboard';
import marked from 'marked';
import { VueMathjax } from 'vue-mathjax';

export default {
  name: 'Editor',
  components: {
    VueMathjax,
  },
  mixins: [colorMxn],
  data() {
    return {
      editor: null,
      btnColor: '#71B7F9',
      loading: false,
      problem: {},
      message: '',
      actionClass: 'success',
      eigenTokens: 0,
      error: '',
      errorType: '',
      showModal: false,
      editorTheme: 'twilight',
      themes: [
        {
          label: 'Chrome',
          value: 'chrome',
        },
        {
          label: 'Clouds',
          value: 'clouds',
        },
        {
          label: 'Crimson Editor',
          value: 'crimson_editor',
        },
        {
          label: 'Dawn',
          value: 'dawn',
        },
        {
          label: 'Dreamweaver',
          value: 'dreamweaver',
        },
        {
          label: 'Eclipse',
          value: 'eclipse',
        },
        {
          label: 'Github',
          value: 'github',
        },
        {
          label: 'Iplastic',
          value: 'iplastic',
        },
        {
          label: 'Solarized Light',
          value: 'solarized_light',
        },
        {
          label: 'Textmate',
          value: 'textmate',
        },
        {
          label: 'Tomorrow',
          value: 'tomorrow',
        },
        {
          label: 'Xcode',
          value: 'xcode',
        },
        {
          label: 'Kuroir',
          value: 'kuroir',
        },
        {
          label: 'Katzenmilch',
          value: 'katzenmilch',
        },
        {
          label: 'SQL Server',
          value: 'sqlserver',
        },
        {
          label: 'Ambiance',
          value: 'ambiance',
        },
        {
          label: 'Chaos',
          value: 'chaos',
        },
        {
          label: 'Clouds Midnight',
          value: 'clouds_midnight',
        },
        {
          label: 'Cobalt',
          value: 'cobalt',
        },
        {
          label: 'Dracula',
          value: 'dracula',
        },
        {
          label: 'Gruvbox',
          value: 'gruvbox',
        },
        {
          label: 'Green On Black',
          value: 'gob',
        },
        {
          label: 'Idle Fingers',
          value: 'idle_fingers',
        },
        {
          label: 'Krtheme',
          value: 'kr_theme',
        },
        {
          label: 'Merbivore',
          value: 'merbivore',
        },
        {
          label: 'Merbivore Soft',
          value: 'merbivore_soft',
        },
        {
          label: 'Mono Industrial',
          value: 'mono_industrial',
        },
        {
          label: 'monokai',
          value: 'monokai',
        },
        {
          label: 'Nord dark',
          value: 'nord_dark',
        },
        {
          label: 'One Dark',
          value: 'one_dark',
        },
        {
          label: 'Pastel On Dark',
          value: 'pastel_on_dark',
        },
        {
          label: 'Solarized Dark',
          value: 'solarized_dark',
        },
        {
          label: 'Terminal',
          value: 'terminal',
        },
        {
          label: 'Tomorrow Night',
          value: 'tomorrow_night',
        },
        {
          label: 'Tomorrow Night Blue',
          value: 'tomorrow_night_blue',
        },
        {
          label: 'Tomorrow Night Bright',
          value: 'tomorrow_night_bright',
        },
        {
          label: 'Tomorrow Night 80s',
          value: 'tomorrow_night_eighties',
        },
        {
          label: 'Twilight',
          value: 'twilight',
        },
         {
          label: 'Vibrant Ink',
          value: 'vibrant_ink',
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['user', 'getSideBarChallenge']),
    markDownHtml() {
      return this.problem.description ? marked(this.problem.description) : '';
    },
    disablePrev() {
      const challenge = this.getSideBarChallenge;
      if (!challenge) {
        return true;
      }

      const chapterSet = challenge.filter((chapter) => chapter.node.pk === parseInt(this.$route.params.chapterId));
      const chapter = chapterSet[0];
      const indexOfChapter = challenge.indexOf(chapter);
      const problemSet = chapter.node.problemSet.edges.filter((problem) => problem.node.pk === parseInt(this.$route.params.problemId));
      const problem = problemSet[0];
      const indexOfproblem = chapter.node.problemSet.edges.indexOf(problem);
      if (indexOfproblem === 0 && indexOfChapter === 0 ) {
        return true;
      }
      return false;
    },
    disableNext() {
      const challenge = this.getSideBarChallenge;
      if (!challenge) {
        return true;
      }
      const chapterSet = challenge.filter((chapter) => chapter.node.pk === parseInt(this.$route.params.chapterId));
      const chapter = chapterSet[0];
      const indexOfChapter = challenge.indexOf(chapter);
      const problemSet = chapter.node.problemSet.edges.filter((problem) => problem.node.pk === parseInt(this.$route.params.problemId));
      const problem = problemSet[0];
      const indexOfproblem = chapter.node.problemSet.edges.indexOf(problem);
      const totalProblems = challenge[indexOfChapter].node.numberOfProblems;

      if (indexOfproblem === (totalProblems - 1) && indexOfChapter === (challenge.length - 1)){
        return true;
      }
      return false;
    }
  },
  apollo: {
    problem: {
      query: PROBLEM_QUERY,
      variables() {
        return {
          'problemPk': parseInt(this.$route.params.problemId),
          'problemChallengeId': parseInt(this.$route.params.challengeId),
        }
      },
      update: (data) => {
        return data.problem;
      },
      error(error) {
        const result = JSON.stringify(error.message);
        this.error = result.split(':')[1].replace('"', '');
      }
    }
  },
  watch:{
    problem(old, val) {
      if (old !== val) {
        this.initEditor();
      }
    },
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    ...mapMutations(['setEigenToken', 'setUpdateChallengeObject']),
    
    initEditor() {
      this.error = null;
      const lang = 'python';
      const theme = localStorage.getItem('editorTheme') ? localStorage.getItem('editorTheme') : 'twilight';
      
      this.editor = window.ace.edit('editor');
      this.editor.setValue( this.problem.myAnswer ? this.problem.myAnswer.answer : this.problem.skeleton);
      this.editor.getSession().setMode(`ace/mode/${lang}`);
      this.editor.setTheme(`ace/theme/${theme}`);
    },
    setEditor() {
      this.editor.setTheme(`ace/theme/${this.editorTheme}`);
      localStorage.setItem('editorTheme', this.editorTheme);
    },
    submitCode() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: POST_ANSWER,
        variables: {
          "testAnswerData": {
            "problemId": parseInt(this.$route.params.problemId),
            "answer": this.editor.getValue()+"\n",
            "arguments": null
          }
        },
        update: (cache, {data, errors}) => {
          if (!errors) {
            this.actionClass = data.testAnswer.error ? 'error' :'success';
            this.errorType = data.testAnswer.error ? data.testAnswer.error.split(':')[0] : '';
            switch (data.testAnswer.message) {
              case 'Correct!':
                this.message = 'Congratulations 🎉! Your answer is correct.';
                this.$root.$emit('updateChallenge',{
                  chapterId: parseInt(this.$route.params.chapterId), 
                  problemId: parseInt(this.$route.params.problemId),  
                  value: true,
                });
                break;
              case '':
                this.message =  data.testAnswer.error.split(':')[1];
                this.$root.$emit('updateChallenge',{
                  chapterId: parseInt(this.$route.params.chapterId), 
                  problemId: parseInt(this.$route.params.problemId), 
                  value: false,
                });
                break;
              case null:
                this.message =  data.testAnswer.error.split(':')[1];
                this.$root.$emit('updateChallenge',{
                  chapterId: parseInt(this.$route.params.chapterId), 
                  problemId: parseInt(this.$route.params.problemId), 
                  value: false,
                });
                break;
              default:
                this.message = data.testAnswer.message;
                break;
            }

            this.eigenTokens = `${data.testAnswer.eigenTokens}`;
            if (data.testAnswer.eigenTokens) {
              this.getTokens();
            }
          }
          this.loading = false;
        },
      }) 
    },
    reset() {
      this.message = '';
      this.actionClass = '';
      this.eigenTokens = 0;
    },
    md(description) {
      return marked(description);
    },
    getTokens() {
      this.$apollo.query({
        query: EIGEN_TOKENS,
      }).then(res => {
        this.setEigenToken(res.data.me.eigenTokens);
      }) 
    },
    resetToSkeleton() {
      this.editor.setValue(this.problem.skeleton);
      this.showModal = false;
    },
    setPrevious() {
      const challenge = this.getSideBarChallenge;
      const chapterSet = challenge.filter((chapter) => chapter.node.pk === parseInt(this.$route.params.chapterId));
      const chapter = chapterSet[0];
      const indexOfChapter = challenge.indexOf(chapter);
      const problemSet = chapter.node.problemSet.edges.filter((problem) => problem.node.pk === parseInt(this.$route.params.problemId));
      const problem = problemSet[0];
      const indexOfproblem = chapter.node.problemSet.edges.indexOf(problem);
      if (indexOfproblem === 0 ) {
        this.prevChapter(indexOfChapter);
        return;
      }
      const prevProblem = chapter.node.problemSet.edges[indexOfproblem - 1];
      const path = `/challenge/${this.$route.params.challengeId}/${chapter.node.pk}/${prevProblem.node.title}/${prevProblem.node.pk}`;
      this.$router.push(path);
    },

    setNext() {
      const challenge = this.getSideBarChallenge;
      const chapterSet = challenge.filter((chapter) => chapter.node.pk === parseInt(this.$route.params.chapterId));
      
      const chapter = chapterSet[0];
      const indexOfChapter = challenge.indexOf(chapter);
      const problemSet = chapter.node.problemSet.edges.filter((problem) => problem.node.pk === parseInt(this.$route.params.problemId));
      const problem = problemSet[0];
      const indexOfproblem = chapter.node.problemSet.edges.indexOf(problem);
      
      const totalProblems = challenge[indexOfChapter].node.numberOfProblems;

      if (indexOfproblem === (totalProblems - 1) && challenge.length !== indexOfChapter ) {
        this.nextChapter(indexOfChapter);
        return;
      }
      const nextProblem = chapter.node.problemSet.edges[indexOfproblem + 1];
      const path = `/challenge/${this.$route.params.challengeId}/${chapter.node.pk}/${nextProblem.node.title}/${nextProblem.node.pk}`;
      this.$router.push(path);
    },
    nextChapter(indexofChapter) {
      const challenge = this.getSideBarChallenge;
      const chapterNext = challenge[indexofChapter + 1];
      const problem = challenge[indexofChapter + 1].node.problemSet.edges[0];
      const path = `/challenge/${this.$route.params.challengeId}/${chapterNext.node.pk}/${problem.node.title}/${problem.node.pk}`;
      this.$router.push(path);
    },
    prevChapter(indexofChapter) {
      const challenge = this.getSideBarChallenge;
      const chapterPrev = challenge[indexofChapter - 1];
      const problem = challenge[indexofChapter - 1].node.problemSet.edges[0];
      const path = `/challenge/${this.$route.params.challengeId}/${chapterPrev.node.pk}/${problem.node.title}/${problem.node.pk}`;
      this.$router.push(path);
    }
  },
}
</script>

<style>
#editor {
  min-width: 500px; 
  min-height: 80vh;
}

.problem-height {
  max-height:100VH;
  overflow-y:scroll;
}

.mathjax-img img {
  display: block;
  margin: 20px auto;
}

.select {
  max-height: 45px;
  max-width: 150px !important;
}

</style>
