import gql from "graphql-tag";

const PROBLEM_QUERY = gql`query($problemPk: Int!, $problemChallengeId: Int!) {
  problem(pk: $problemPk, challengeId: $problemChallengeId) {
    pk,
    title
    skeleton,
    description
    createdDate,
    myAnswer {
      answer
    }
  }
}`;

const  POST_ANSWER = gql`mutation($testAnswerData: AnswerInput!)  {
  testAnswer(data: $testAnswerData) {
    error
    message
    eigenTokens
  }
}`;

export {
  PROBLEM_QUERY,
  POST_ANSWER,
}