import gql from 'graphql-tag';

const BADGES = gql`query {
  myBadges {
    edges {
      node {
        badge {
          badgeName
          eignetokenReward
          badgeIconImage
        }
        dateAwarded
      }
    }
  }
}`

const ENROLLED_PROBLEMS= gql`query Query {
  enrolledProblems {
    totalCount
  }
}`

const ENROLLED_CHALLENGES_COUNT = gql`query {
  enrolledChallenges {
    totalCount
  }
}`;


const EIGEN_TOKENS= gql`query {
  me {
    eigenTokens
  }
}` 


const DASHBOARD_QUERY = gql`query($challengesFirst: Int) {
  enrolledChallenges(first: $challengesFirst) {
    totalCount
    edges {
      cursor
      node {
        id
        preTitle
        title
        minDescription
        solvedProblems {
          totalCount
          edgeCount
        }
        chapterSet {
          edgeCount
          edges {
            node {
              problemSet {
                edgeCount
              }
            }
          }
        }
        pk
      }
    }
  }
  enrolledProblems {
    totalCount
  }
  myBadges {
    totalCount
    edges {
      node {
        badge {
          badgeName
          eignetokenReward
          badgeIconImage
        }
        dateAwarded
      }
    }
  }
}`

export {
  BADGES,
  ENROLLED_PROBLEMS,
  ENROLLED_CHALLENGES_COUNT,
  DASHBOARD_QUERY,
  EIGEN_TOKENS,
}